import React, { useState, useEffect } from 'react'
import './property-page.scss'
import { graphql, Link } from 'gatsby'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import Layout from '../components/Layout/layout'
import PopupForm from '../components/PopupForm/popup-form'
import PropertyDetailTopContent from "../components/PropertyDetail/PropertyDetailTopContent/PropertyDetailTopContent"
import PropertyDetailLightbox from "../components/PropertyDetail/PropertyLightbox/PropertyDetailLightbox"
import PropertyDetailContent from "../components/PropertyDetail/PropertyDetailContent/PropertyDetailContent"
import SimilarProperty from "../components/PropertyDetail/SimilarProperty/similar-property"
import { filter } from 'lodash'


export const query = graphql`
    query($slug: String!, $type: String!, $minprice: Int!, $maxprice: Int!, $isInternationalProperty: Boolean!) {
        property: contentfulProperty(slug: {eq: $slug}) {
            seo {
                metaTitle
                metaDescription {
                    metaDescription
                }
            }
            propertyImages {
              gatsbyImageData(
                aspectRatio: 1.5
                cropFocus: TOP
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 1900
              )
              title
              file {
                url
                fileName
                contentType
              }
            }
            images{
              url
            }
            provider
            propertyTitle
            propertyOneLiner
            propertyDescription {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                  title
                }
              }
            }
            propertyId
            propertyPrice
            propertyInternationalPrice
            internationalCurrency
            propertyType
            slug
            lotSize
            sizeInSquareMeters
            neighborhood {
              title
              slug
            }
            numberOfBathrooms
            numberOfBedrooms
            numberOfParking
            isThisANewDevelopment
            isThisAnOffPlanDevelopmentProperty
            paymentPlan
            paymentFrequency
            propertyCategory
            cityAndCountry
            facilities
            features 
            agent {
              name
              email
              phoneNumber
              jobTitle
              address {
                address
              }
              image {
                gatsbyImageData(
                  aspectRatio: 1.5
                  cropFocus: BOTTOM
                  layout: FULL_WIDTH
                  placeholder: BLURRED  
                )
                title
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
        }
        similarProperty:  allContentfulProperty (limit: 10, filter: {propertyType: {eq: $type}, propertyPrice:{gte : $minprice, lte : $maxprice} ,isThisAnInternationalProperty: {eq: $isInternationalProperty}}) {        
          edges {
            node {
                slug
                propertyPrice
                propertyType
                propertyId
                propertyImages {
                  gatsbyImageData(
                  layout: FULL_WIDTH
                  width: 600
                  height: 500
                  cropFocus: CENTER
                  placeholder: BLURRED
                  aspectRatio: 1
                  )
                  title
                }
                images{
                  url
                }
                provider
                neighborhood {
                  title
                }
                propertyCategory
                propertyOneLiner
                isThisAnInternationalProperty
            }
          }
        }
        agent: contentfulAgents(email: {eq: "marketing@25spaces.com"}) {
            name
            email
            phoneNumber
            jobTitle
            address {
              address
            }
            image {
              gatsbyImageData(
                aspectRatio: 1.5
                cropFocus: BOTTOM
                layout: FULL_WIDTH
                placeholder: BLURRED  
              )
              title
              file {
                url
                fileName
                contentType
              }
            }
        }
    }
`

const PropertyPage = ({ data: { property, similarProperty, agent } }) => {

  const [openRequestForm, setOpenRequestForm] = useState(false)
  const [openViewingForm, setOpenViewingForm] = useState(false)
  const [windowLocation, setWindowLocation] = useState()


  const handleOpenRequestForm = () => {
    setOpenRequestForm(true)
  }
  const handleCloseRequestForm = () => {
    setOpenRequestForm(false)
  }

  const handleOpenViewingForm = () => {
    setOpenViewingForm(true)
  }
  const handleCloseViewingForm = () => {
    setOpenViewingForm(false)
  }

  useEffect(() => {
    setWindowLocation(window.location.href);
  })


  var similarPropertyList = [];
  if (similarProperty) {
    similarPropertyList = filter(
      similarProperty?.edges, function (o) {
        return property.propertyId !== o.node.propertyId;
      }
    );
  }
  
  let neighborhoods = property?.neighborhood?.title 
  let propertyType = property?.propertyType[0].replace("Commercial Rent","Rent").replace("Commercial Sale","Sale").replace("Buy","Sale")
  const propertyOnliner = property?.propertyOneLiner.replace(/  +/g, ' ').replace("RETAIL","RETAIL SPACE").toLowerCase() 
  //console.log("propertyOnliner",propertyOnliner)
  //function to capitalize the first letter of ezach word
  const words = propertyOnliner.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
    }

  var capitalizePropertyOnliner = words.join(" ");

  var seoTitle = `${capitalizePropertyOnliner} for ${propertyType} in ${neighborhoods} | 25 Spaces Real Estate`
  var seoDesc = `${capitalizePropertyOnliner} for ${propertyType} in ${neighborhoods}. Contact 25 Spaces Real Estate for more information about the property.`
  
  return (

    <Layout className="property-detail" formIntro={property.propertyTitle}>

      <SeoHelmet
        metaTitle={seoTitle}
        metaDescription={seoDesc}
        shareImage={property.propertyImages}
        images={property.images}
        provider={property.provider}
        shareUrl={windowLocation}
        shareTitle={property.propertyTitle}
      ></SeoHelmet>
      <PropertyDetailLightbox
        propertyImages={property.propertyImages}
        images={property.images}
        isMobile={true}
        provider={property.provider}
      />
      <PropertyDetailTopContent
        title={property.propertyTitle}
        price={property.propertyPrice}
        category={property.propertyCategory}
        type={property.propertyType}
        neighborhood={property.neighborhood?.title}
        paymentFrequency={property.paymentFrequency}
        beds={property.numberOfBedrooms}
        baths={property.numberOfBathrooms}
        parking={property.numberOfParking} 
        sqmeters={property.sizeInSquareMeters}
        openViewing={handleOpenViewingForm}
        internationalPrice={property.propertyInternationalPrice ? property.propertyInternationalPrice : ""}
        internationalCurrency={property.internationalCurrency ? property.internationalCurrency : ""}
        offPlanDevelopment={property.isThisAnOffPlanDevelopmentProperty}
        paymentPlan={property.paymentPlan}
      />
      <PropertyDetailLightbox
        propertyImages={property.propertyImages}
        images={property.images}
        isMobile={false}
        provider={property.provider}
      />
      <PropertyDetailContent
        openRequest={handleOpenRequestForm}
        openViewing={handleOpenViewingForm}
        shareUrl={windowLocation}
        agentDefault={agent}
        {...property}
      />
      <SimilarProperty similarProperty={similarPropertyList} isCommercial={false} />
      <PopupForm
        closeForm={handleCloseRequestForm}
        openForm={openRequestForm}
        formTitle="Request Details for"
        formIntro={property.propertyTitle}
      ></PopupForm>

      <PopupForm
        closeForm={handleCloseViewingForm}
        openForm={openViewingForm}
        formTitle="Schedule a Viewing for"
        formIntro={property.propertyTitle}
      ></PopupForm>
    </Layout>

  )
}

export default PropertyPage